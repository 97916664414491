@media (max-width: $smallWidth1) {
  .home-left-desktop {
    display: none;
  }

  .home-right-desktop {
    display: none;
  }

  .home-left-mobile {
    display: inline;
  }
}

@media (min-width: $smallWidth1_Min) {
  .home-left-desktop {
    display: inline;
    width: 35vw;
    height: 100vh;
    float: left;

    overflow-y: overlay !important;

    .home-left {
      border-right: 1px solid;
    }
  }

  .home-right-desktop {
    display: inline;
    height: 100vh;
    width: 65vw;
    float: right;
    overflow: hidden;
  }

  .home-left-mobile {
    display: none;
  }

  .link-to-mobile-map {
    display: none;
  }
}

.home-left-section {
  position: fixed;
  overflow: hidden;
  height: 100%;
  width: 35%;

  @media (max-width: $smallWidth1) {
    width: 100%;
  }
}

.home-left {
  position: relative;
  height: 100%;
  width: 100%;
  background: rgb(48, 48, 48);

  overflow-y: scroll; // scroll
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  .home-left-logo {
    width: auto;
    max-width: 500px;
    position: relative;
    padding: 2rem;
    top: 0;
    left: 0;
    z-index: 0;

    img {
      width: 99%;
    }

    @media (max-width: $smallWidth1) {
      width: 50vw;
    }
  }

  .home-left-heading {
    @include Headline1();
    font-size: 4.1vw;
  }

  .home-left-text {
    @include SectionBody();
    font-size: 1.2vw;
    padding: 0rem 2rem 2rem 2rem;
  }

  .sponsor-images {
    padding: 0rem 2rem 2rem 2rem;
  }

  .sponsors {
    height: 60px;
    padding: 3px;
  }
}

.home-left::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.home-right {
  // overflow: hidden;

  .map-main {
    width: 100%;
    height: 100%;

    img {
      pointer-events: none;
    }
  }

  .map-box {
    // position: absolute;
    cursor: move;
    border-radius: 5px;
    padding: 1rem 1rem 0rem 0rem;
    user-select: none;
    // width: 70vw;
    // min-width: 1100px;
    position: relative;

    @media (max-width: $smallWidth1) {
      // max-width: auto;
      min-width: 120%;
      padding-left: 0%;
      // width: 120%;
    }
  }

  .map-image-box {
    // width: 100%;
    // height: auto;
    position: relative;
    z-index: 1;
  }

  .map-image {
    width: 100%;
    height: auto;
  }

  .button-1 {
    position: absolute;
    z-index: 2;
    right: 14%;
    top: 51%;
  }

  .button-2 {
    position: absolute;
    z-index: 2;
    transform-origin: bottom;
    transform: rotate(0deg);
    right: 15%;
    top: 42%;
  }

  .button-3 {
    position: absolute;
    z-index: 2;
    right: 35%;
    top: 32%;
  }

  .button-4 {
    position: absolute;
    z-index: 2;
    right: 60%;
    top: 35%;
  }

  .button-5 {
    position: absolute;
    z-index: 2;
    right: 45%;
    top: 55%;
  }

  .nav-buttons {
    button {
      width: 150px;
      height: 50px;
      background: $darkGrey;

      &:active {
        background: $beige;
      }

      @media (min-width: $smallWidth1_Min) {
        &:hover {
          background: $beige;
        }
      }
    }

    .case-study-buttons {
      width: 2vw;
      height: auto;
    }
  }

  .page-link {
    color: $darkGrey;
  }

  .map-popup-bg {
    font-size: 10px;
    padding: 0.5rem 1rem 0.5rem 1rem;
  }
  .map-pin-links {
    z-index: 10;
  }
}

.nav-links {
  padding: 0rem 2rem 0.6rem 2rem;
}

// Cookies

.react-cookie-law-dialog {
  position: absolute;
  z-index: 100;
  width: 100vw;
  bottom: 0;
  background: $beige;
  // color: red;
  padding: 1rem;

  .react-cookie-law-manage-btn {
    display: none !important;
  }

  .react-cookie-law-policy {
    display: none !important;
  }
}
