// .case-studies {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   transform: translateX(100%);
//   transition: all 0.5s ease;

//   background-color: $darkGrey;
//   visibility: hidden;
// }

.case-study-inner {
  position: fixed;
  overflow-y: scroll; // scroll
  height: 100%;

  // Old scroll-related code - creates bugs in scrolling
  // width: 100%;
  // padding-bottom: 4rem;
  // padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  // box-sizing: content-box; /* So the width will be 100% + 17px */
}

.active-case-studies {
  transform: translateX(0%);
  visibility: visible;
}

.case-study-column-sections {
  padding-top: 3rem;
  padding-bottom: 4rem;
  width: 75vw; // 75vw
  position: relative;
  margin: auto;

  display: flex;
  flex-direction: column;
}

.case-study-1 {
  a {
    color: $csPurpleLight;
  }
}

.case-study-2 {
  a {
    color: $csBlueLight;
  }
}

.case-study-3 {
  a {
    color: $csGreenLight;
  }
}

.case-study-4 {
  a {
    color: $csYellowLight;
  }
}

.case-study-5 {
  a {
    color: $csOrangeLight;
  }
}

.case-study-intro {
  padding-top: 1rem;
}

.case-study-intro-title {
  @include Headline1();
  font-size: 3.8vw;
  @media (max-width: $smallWidth1) {
    width: 89%;
  }
}

.case-study-intro-body {
  padding-top: 2rem;
  padding-bottom: 0rem;
  @include SectionBody();
  font-size: 1.2vw;
}
