// Desktop
.sources {
  //   position: fixed;
}

.sources-logo {
  width: 10vw;
  float: left;
  padding-left: 1rem;
  padding-top: 1rem;
}

.sources-inner {
  z-index: -1;
  position: fixed;
  overflow: hidden;
  height: 100%;
  width: 100%;

  .sources-column {
    width: 70vw;
    height: 100%;
    position: relative;
    margin: auto;
    padding-top: 3rem;
    padding-bottom: 3rem;
    overflow-y: scroll; // scroll
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  .sources-column::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  .title {
    @include Headline1();
    font-size: 5.8vw;
  }

  ul {
    margin-left: 0;
    padding-left: 3rem;
    @include SectionBody();
    font-size: 1.2vw;
  }

  p {
    @include SectionBody();
    font-size: 1.2vw;
  }

  h2 {
    @include Headline1();
    font-size: 2.2vw;
    padding-top: 2rem;
  }

  .sponsors {
    height: 100px;
    padding: 3px;
  }
}

// Mobile
@media (max-width: $smallWidth1) {
  .sources {
    background: red;
  }
}

.sponsor-images {
  display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: -3px;
    margin-right: -3px;
    margin-top: 1rem;
}






