.nav-page {
  margin: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    margin: 2rem;
    background: rgb(224, 224, 224);
    border: none;
    border: 2px solid rgb(224, 224, 224);
    border-radius: 4px;
    cursor: pointer;
    padding: 0.5rem;
    transition: all 0.3s ease;
    color: rgb(65, 65, 65);

    &:hover {
      color: rgb(224, 224, 224);
      background: rgb(65, 65, 65);
    }
  }

  .left-col {
    width: 36vw;
    display: block;
    margin-left: auto;
    margin-right: auto;

    img {
      width: 100%;
    }

    p {
      padding-top: 2rem;
    }
  }

  .right-col {
    width: 61vw;
    display: flex;
    flex-direction: column;

    .map-and-buttons {
      position: relative;
    }

    button {
      position: absolute;
      right: 16%;
      top: 40%;
    }

    img {
      position: relative;
      display: block;
      float: right;
      margin-right: 0px;
    }
  }
}
